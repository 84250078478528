import { useEffect, useLayoutEffect, useState } from "react";
import "../../scss/global.scss";
import "./home.scss";
import animationData from "../../animations/data.json";
import Lottie from "react-lottie";
import { motion } from "framer-motion";
import Door from "../../animations/door";
import Gallery from "../../components/gallery/gallery";

const Home: React.FC = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    }, []);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet",
        },
    };

    const navigateToFooter = () => {
        const footerRef = document.getElementById(
            "footer"
        ) as HTMLElement | null;

        footerRef?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="container">
            <div className="hero">
                <div className="home-one">
                    <motion.div
                        initial={{
                            top: `calc(50% - 155px)`,
                            left: "calc(50% - 182.5px)",
                            height: "280px",
                            width: "365px",
                        }}
                        animate={{
                            top: "-80px",
                            height: "175px",
                            left: "calc(50% - 117.5px)",
                            width: "235px",
                        }}
                        transition={{
                            duration: 1,
                            delay: 4,
                            type: "easeInOut",
                        }}
                        className="lottie-wrapper"
                    >
                        <Lottie
                            options={defaultOptions}
                            height={"100%"}
                            width={"auto"}
                            style={{
                                zIndex: 400,
                                position: "relative",
                                overflowX: "hidden",
                            }}
                        />
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 8, duration: 1 }}
                        className="contact-wrapper"
                    >
                        <div className="corner-box"></div>
                        <div className="corner-box"></div>
                        <div className="corner-box"></div>
                        <div className="corner-box"></div>
                        <motion.div                            
                            className="contact"
                        >
                            <div className="corner-box"></div>
                            <div className="corner-box"></div>
                            <div className="corner-box"></div>
                            <div className="corner-box"></div>

                            <a href="../dl/brochure.pdf" target="_blank"><h3>DOWNLOAD<br></br> BROCHURE</h3></a>
                        </motion.div>
                    </motion.div>
                    <div className="left-side">
                        <motion.div
                            initial={{ height: "100%" }}
                            animate={{ opacity: 0 }}
                            transition={{ duration: 2, delay: 6 }}
                            className="hide"
                        ></motion.div>
                        <div className="copy-wrapper">
                            <h1>Boutique</h1>
                            <p>
                                10,472 sq ft of self contained <br /> office
                                space across four floors
                            </p>
                        </div>
                        <div className="green-box"> </div>
                        <div className="dark-green-box"> </div>
                    </div>
                    <div className="right-side">
                        <motion.div
                            initial={{ height: "100%" }}
                            animate={{ opacity: 0 }}
                            transition={{ duration: 2, delay: 6 }}
                            className="hide"
                        ></motion.div>
                        <div className="copy-wrapper">
                            <h1>Unique</h1>
                        </div>
                        <div className="green-box"> </div>
                        <div className="dark-green-box"> </div>
                    </div>
                </div>
                <div className="home-two">
                    <div className="container">
                        <motion.h4
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            YOUR OWN FRONT DOOR TO COVENT GARDEN
                        </motion.h4>
                        <div className="img-wrapper">
                            <Door />
                        </div>
                    </div>
                </div>
                <div className="home-image">
                    <Gallery
                        captions={[
                            "The Building",
                            "Reception",
                            "First Floor",
                            "Second Floor",
                            "Fourth Floor Terrace",
                            "End of Journey",
                            "End of Journey",
                        ]}
                        images={[
                            "./images/house.jpg",
                            "./images/gallery/3.jpg",
                            "./images/gallery/4.jpg",
                            "./images/gallery/5.jpg",
                            "./images/gallery/6.jpg",
                            "./images/gallery/2.jpg",
                            "./images/gallery/1.jpg",
                        ]}                        
                    />
                </div>
                <div className="home-three">
                    <motion.h2
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        Elegance Elevated
                    </motion.h2>
                    <div className="icons">
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: !isMobile ? 0 : 0,
                            }}
                            viewport={{ once: true }}
                            className="icon"
                        >
                            <div className="img-wrapper">
                                <img src="../images/icons/1.svg" alt="icon" />
                            </div>
                            <div className="disc-wrapper">
                                <h5>
                                    Your own <br /> front door
                                </h5>
                            </div>
                            <p>
                                Experience your own front door to Covent Garden
                            </p>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: !isMobile ? 0.25 : 0,
                            }}
                            viewport={{ once: true }}
                            className="icon"
                        >
                            <div className="img-wrapper">
                                <img src="../images/icons/2.svg" alt="icon" />
                            </div>
                            <div className="disc-wrapper">
                                <h5>10,472 sq ft</h5>
                            </div>
                            <p>
                                of self contained office space over four floors.
                            </p>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: !isMobile ? 0.5 : 0,
                            }}
                            viewport={{ once: true }}
                            className="icon"
                        >
                            <div className="img-wrapper">
                                <img src="../images/icons/3.svg" alt="icon" />
                            </div>
                            <div className="disc-wrapper">
                                <h5>RESTORED</h5>
                            </div>
                            <p>
                                Carefully restored heritage features re-imagined
                                as part of a modern office environment.
                            </p>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: !isMobile ? 0.75 : 0,
                            }}
                            viewport={{ once: true }}
                            className="icon"
                        >
                            <div className="img-wrapper">
                                <img src="../images/icons/4.svg" alt="icon" />
                            </div>
                            <div className="disc-wrapper">
                                <h5>facilities</h5>
                            </div>
                            <p>
                                Fully refurbished end of journey facilities
                                including showers, lockers and changing rooms.
                            </p>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: !isMobile ? 0.25 : 0,
                            }}
                            viewport={{ once: true }}
                            className="icon"
                        >
                            <div className="img-wrapper">
                                <img src="../images/icons/9.svg" alt="icon" />
                            </div>
                            <div className="disc-wrapper">
                                <h5>terrace</h5>
                            </div>
                            <p>
                                Outdoor space on fourth floor, a rarity in
                                central London.
                            </p>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: !isMobile ? 0.5 : 0,
                            }}
                            viewport={{ once: true }}
                            className="icon"
                        >
                            <div className="img-wrapper">
                                <img src="../images/icons/6.svg" alt="icon" />
                            </div>
                            <div className="disc-wrapper">
                                <h5>
                                    occupancy
                                    <br />
                                    density
                                </h5>
                            </div>
                            <p>1:10</p>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: !isMobile ? 0.75 : 0,
                            }}
                            viewport={{ once: true }}
                            className="icon"
                        >
                            <div className="img-wrapper">
                                <img src="../images/icons/7.svg" alt="icon" />
                            </div>
                            <div className="disc-wrapper">
                                <h5>restaurant</h5>
                            </div>
                            <p>
                                Newly refurbished Japanese restaurant, Akatuki
                                below.
                            </p>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: !isMobile ? 0.75 : 0,
                            }}
                            viewport={{ once: true }}
                            className="icon"
                        >
                            <div className="img-wrapper">
                                <img src="../images/icons/8.svg" alt="icon" />
                            </div>
                            <div className="disc-wrapper">
                                <h5>14 new cycle spaces</h5>
                            </div>
                            <p>Provided in double stack racks.</p>
                        </motion.div>
                    </div>
                </div>
                <div className="home-four" id="footer">
                    <motion.div className="contact-wrapper">
                        <div className="corner-box"></div>
                        <div className="corner-box"></div>
                        <div className="corner-box"></div>
                        <div className="corner-box"></div>
                        <div className="contact">
                            <div className="corner-box"></div>
                            <div className="corner-box"></div>
                            <div className="corner-box"></div>
                            <div className="corner-box"></div>
                            <a ><motion.h3 >GET IN CONTACT</motion.h3></a>
                        </div>
                    </motion.div>
                    <div className="left-side">
                        <div className="corner-square"></div>
                        <div className="corner-square"></div>
                        <div className="content">
                            <p>INTERESTED?</p>
                            <p>
                                BOOK IN A VIEWING WITH <br /> ONE OF OUR AGENTS
                            </p>
                            <div className="img-wrapper">
                                <img src="../images/footer-vec.svg" alt="vec" />
                            </div>
                        </div>
                    </div>
                    <div className="right-side">
                        <div className="corner-square"></div>
                        <div className="corner-square"></div>
                        <div className="content">
                            <p>INTERESTED?</p>
                            <p>
                                BOOK IN A VIEWING WITH <br /> ONE OF OUR AGENTS
                            </p>
                            <div className="img-wrapper">
                                <img src="../images/footer-vec.svg" alt="vec" />
                            </div>
                        </div>
                        <div className="img-wrapper">
                            <img src="../images/cbre.png" alt="cbre" />
                        </div>
                        <div className="agent">
                            <p>Alex Kerr</p>
                            <a href="mailto:Alex.Kerr@cbre.com">
                                <p>Alex.Kerr@cbre.com</p>
                            </a>
                            <p>07557 353 990</p>
                        </div>
                        <div className="agent">
                            <p>Nathalie Spink</p>
                            <a href="mailto:Nathalie.Spink@cbre.com">
                                <p>Nathalie.Spink@cbre.com</p>
                            </a>
                            <p>07385 414 222</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <p>©2024. All rights reserved.</p>
            </div>
        </div>
    );
};

export default Home;
